.custom-file-input ~ .custom-file-label::after {
	border-radius: 0 0 0 0 !important;
}

.tooltip {
	pointer-events: none;
}

@media (max-width: 992px) {
	:root {
		font-size: 14px;
	}
}

/* @media (max-width: 768px) {
	:root {
		font-size: 12px;
	}
} */
